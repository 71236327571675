@import url('https://fonts.googleapis.com/css?family=Muli');
@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans');
@import url('https://fonts.googleapis.com/css?family=Archivo');

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	min-height: 60vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: white;
	font-size: calc(10px + 2vmin);

	.logo-text {
		margin-top: -260px;
		h1 {
			font-family: 'Archivo';
			font-size: 2.5em;
			font-weight: bold;
		}
	}
}

p {
	color: darkgrey;
	font-size: calc(10px + 2vmin);
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.tv-logo {
	width: 400px;
	height: 400px;
}

// Base Code Widget
.base-code-widget {
	margin-top: 2em;
	display: flex;
	flex-direction: column;
	align-items: center;

	input {
		text-align: center;
	}

	p {
		color: white;
		font-family: 'Muli';
	}
}

// Player
.player {
	h1 {
		color: white;
	}
}
// Autosize shots.
.splide__slide img {
	width: 100%;
	height: auto;
}
